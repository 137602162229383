.users-page .create-user {
  margin-right: 20px;
}
.users-page .create-user button {
  padding: 0px 10px !important;
}
.users-page .export {
  margin-right: 20px;
}
.users-page .criterias {
  margin-top: 10px;
}
.users-page .criterias span {
  cursor: pointer;
  font-size: 12px;
  padding: 10px;
  background-color: #d6d6d6;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
}
.users-page .criterias span.active {
  background-color: #00BFA5;
  color: white;
}

