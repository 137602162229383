.service-page .error {
  font-size: 20px;
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-weight: 200;
  line-height: 26px;
}
.service-page .article.draft label {
  color: #f39d00 !important;
}
.service-page .article.published label {
  color: #33d400 !important;
}
.service-page .article-preview .article-title {
  font-weight: bold;
  font-size: 24px;
}
.service-page .article-preview .article-description video {
  max-width: 400px;
}

