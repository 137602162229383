.dashboard-block-wrapper {
  margin-bottom: 10px;
  padding: 0px 5px;
  .dashboard-block {
    height: auto;
    padding: 10px;
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 4px;
    &.align-center {
      text-align: center;
    }
    &.square {
      height: 335px;
    }
    .canvas {
      margin: auto;
    }
    .title {
      text-align: left;
      margin: -10px;
      padding: 0px 10px;
      margin-bottom: 5px;
      background-color: #fbfbfb;
      font-size: 0.9em;
      height: 35px;
      line-height: 35px;
    }
  }
}