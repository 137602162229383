.status-list ul li {
  color: #c1c1c1;
}
.status-list ul li.pending {
  color: #5c80ec;
}
.status-list ul li.active {
  color: #00BFA5;
}

