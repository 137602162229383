@import 'assets/styles/variable.scss';

.payments-page {
  .switcher {
    text-align: center;
    margin-bottom: 20px;
    button {
      &.active,
      &:hover {
        background: $primaryColor;
        color: white;
        border-color: $primaryColor;
      }
      padding: 12px 16px;
      background: #ffffff;
      font-size: 16px;
      margin: 0px 4px;
      box-shadow: none;
      cursor: pointer;
      border: 1px solid #d6d3d3;
      border-radius: 4px;
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.06);
    }
  }
  .services {
    .service-line {
      margin-bottom: 20px;
      cursor: pointer;
      padding: 16px 8px;
      background: #eaeaea;
      font-weight: 400;
      .service-informations {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .service-payment {
        margin-top: 10px;
        font-size: 14px;
      }

      .service-payment-detail {
        margin-top: 10px;
        font-size: 14px;
        .payment-user {
          margin-bottom: 10px;
          padding: 8px 10px;
          border-radius: 2px;
          background: white;
          color: #777;
          .email.hide span {
            display: none;
          }
        }
      }

      .title {
        font-weight: 600;
      }

      .price {
        flex-shrink: 0;
        font-weight: 500;
      }
      &:nth-child(odd) {
        background: white;
      }
      &:hover,
      &.active {
        background-color: #578ae6;
        color: white;
      }
    }

    .hide-email {
      button {
        padding: 8px 16px;
        border: none;
        background: #ffffff;
        font-size: 16px;
        margin-bottom: 8px;
        box-shadow: none;
        cursor: pointer;
      }
    }
  }
}
