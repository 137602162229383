@import 'assets/styles/variable.scss';

.sidebar {
  width: $sidebarWidth;
  position: fixed;
  height: 100%;
  background-color: #383838;
  padding: 20px 10px;
  height: 100%;
  z-index: 100;
  .nav-group {
    margin-bottom: 30px;
  }
  .nav-title {
    color: #b4b3bb;
    margin-bottom: 10px;
  }
  .nav-el {
    color: #e2e2e2;
    padding: 15px 5px;
    display: block;
    cursor: pointer;
    &:hover {
      background-color: #464444;
      color: $primaryColor;
    }

    i {
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 16px;
      &.users {
        // background-image: url('/icons/navbar/users.svg');
        background-size: cover;
      }
    }

    &.active {
      color: $primaryColor;
    }
  }

  a {
    color: inherit;
    text-decoration: initial;
  }

  @media only screen and (max-width:$breakpoint-medium) {
    & {
      width: $sidebarCompactWidth;
    }
    .nav-title {
      display: none;
    }
    .nav-el span {
      display: none;
    }
  }

  @media only screen and (max-device-width:$breakpoint-medium) {
    & {
      width: $sidebarCompactWidth;
    }
    .nav-title {
      display: none;
    }
    .nav-el span {
      display: none;
    }
  }
}

.mobile-navbar {
  /*ul li {
    opacity: 1 !important;
    visibility: visible !important;
  }*/
  display: none;
  button {
    background-color: $primaryColor !important;
  }
  
  ul li a span > div, ul li a span > img {
    float: left !important;
  }

  @media only screen and (max-device-width:1400px) {
    & {
      display: block;
    }
  }

  @media only screen and (max-width:$breakpoint-medium) {
    & {
      display: block;
    }
  }
}