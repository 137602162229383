.notification-page .notification-preview {
  background: #efefef;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #444;
  max-width: 350px;
}
.notification-page .notification-preview .notification-header {
  font-size: 13px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.notification-page .notification-preview .notification-title {
  font-weight: 500;
  margin-bottom: 4px;
}
.notification-page .border {
  height: 1px;
  background: #ccc;
  margin: 30px 0px;
}
.notification-page .info {
  margin: 6px 0px;
  font-size: 13px;
}
.notification-page .error-message {
  margin: 6px 0px;
  color: red;
  font-size: 13px;
}
.notification-page .notifications {
  margin-top: 100px;
}
.notification-page .notifications .notification {
  border-bottom: 1px solid #444;
  padding: 30px 8px;
}
.notification-page .notifications .notification:nth-child(odd) {
  background-color: #eaeaea;
}
.notification-page .notifications .notification:nth-child(odd) .notification-preview {
  background-color: #dad2d2;
}
.notification-page .notifications .notification:last-child {
  border: none;
}
.notification-page .notifications .notification .notification-preview {
  margin-bottom: 6px;
}
.notification-page .notifications .notification .notification-data {
  padding-left: 4px;
  font-size: 13px;
}
.notification-page .notifications .notification .notification-data > div {
  margin-bottom: 4px;
}
.notification-page .notifications .notification .notification-data .device-item {
  padding-left: 10px;
  margin-bottom: 8px;
}

