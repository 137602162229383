table tr {
  /*cursor: pointer;*/
}
table tr.archived {
  background-color: #ffdfdf;
}
table tr.done {
  background-color: #e7ffdf;
}
table td {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 400;
}
table td a svg {
  color: rgb(160, 160, 160) !important;
}
table td a {
  color: #00BFA5;
  width: 100%;
  height: 100%;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
}
table img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: block;
}
table.unselectable thead tr > td:first-child, table.unselectable thead tr > th:first-child, table.unselectable tbody tr > td:first-child, table.unselectable tbody tr > th:first-child {
  display: none;
}

