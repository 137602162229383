.filters {
  margin-bottom: 20px;
}
.filters .label {
  margin-bottom: 5px;
}
.filters .el-col {
  padding: 0px 10px;
}

