@import 'assets/styles/variable.scss';

/* ************************ GENERIC STYLE ************************ */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: white;
  font-family: 'Roboto', Arial;
  font-size: $textFont;
  font-weight: 300;
  background-color: #f5f5f5;
  word-break: break-word;
  color: $textColor;
  &.glue {
    overflow: hidden;
    @media (max-device-width: 1244px) {
      position: fixed;
      left: 0px;
      bottom: 0px;
      right: 0px;
      top: 0px;
    }
  }
}

* {
  box-sizing: border-box;
  outline: 0;
}

.app {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  & > div {
    height: 100%;
  }
}

.detail-page {
  max-width: 700px;
  margin: auto;
}

/* *** font-weight *** */

.weight300 {
  font-weight: 300;
}

.weight400 {
  font-weight: 400;
}

.weight500 {
  font-weight: 500;
}

/* *** margin *** */

.mgt20 {
  margin-top: 20px !important;
}

.mgt10 {
  margin-top: 10px !important;
}

.mgb20 {
  margin-bottom: 20px !important;
}

.mgb10 {
  margin-bottom: 10px !important;
}

.mghauto {
  margin: 0px auto;
}

.margin-auto {
  margin: auto;
}

/* *** display & align *** */

.display-block {
  display: block;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.display-flex {
  display: flex;
}

.inline-top {
  display: inline-block;
  vertical-align: top;
}

.inline-middle {
  display: inline-block;
  vertical-align: middle;
}

/* *** transition *** */

.fade {
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

/* ************************ SPECIFIC LESPEAKERS BACKOFFICE ************************ */

@font-face {
  font-family: 'Roboto';
  src: url(../../assets/fonts/roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(../../assets/fonts/roboto/Roboto-Thin.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Roboto';
  src: url(../../assets/fonts/roboto/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url(../../assets/fonts/roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

.block {
  background-color: white;
  padding: 20px;
  box-shadow: 2px 2px 2px rgba(51, 65, 87, 0.28);
  border: 1px solid rgb(230, 230, 230);
  margin-bottom: 20px;
  @media only screen and (max-width: $breakpoint-xsmall) {
    & {
      padding: 5px !important;
    }
  }
}

h1 {
  font-size: 1.5em;
  font-weight: 400;
  color: $primaryColor;
}

h2 {
  font-size: 1.2em;
  font-weight: 400;
  color: $primaryColor;
}

.primary {
  color: $primaryColor;
}

.picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.form-group {
  margin-bottom: 20px;
}

// .form-group.error .form-select__control {
//   border: 1px solid red !important;
//   box-shadow: none !important;
// }

// .form-group.error > input {
//   border: 1px solid red !important;
//   box-shadow: none !important;
// }

// .form-group.error .form-select__control input {
//   border: 1px solid red !important;
//   box-shadow: none !important
// }

/* *** FIX FRAMEWORK *** */

.el-radio {
  white-space: initial;
}

.el-pagination {
  text-align: center;
  margin: 20px 0px;
}

.el-textarea__inner {
  min-height: 250px;
}

.el-table .cell {
  word-break: initial;
}

.raised button {
  color: white !important;
  padding: 0 10px !important;
}
