.users-detail-page {
  .picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 2px solid black;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
