.notification-page {
  .notification-preview {
    background: #efefef;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
    color: #444;
    max-width: 350px;
    .notification-header {
      font-size: 13px;
      margin-bottom: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .notification-title {
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
  .border {
    height: 1px;
    background: #ccc;
    margin: 30px 0px;
  }
  .info {
    margin: 6px 0px;
    font-size: 13px;
  }
  .error-message {
    margin: 6px 0px;
    color: red;
    font-size: 13px;
  }
  .notifications {
    margin-top: 100px;
    .notification {
      border-bottom: 1px solid #444;
      padding: 30px 8px;
      &:nth-child(odd) {
        background-color: #eaeaea;
        .notification-preview {
          background-color: #dad2d2;
        }
      }
      &:last-child {
        border: none;
      }
      .notification-preview {
        margin-bottom: 6px;
      }
      .notification-data {
        padding-left: 4px;
        font-size: 13px;
        & > div {
          margin-bottom: 4px;
        }
        .device-item {
          padding-left: 10px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
