.service-page {
  .error {
    font-size: 20px;
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 4px;
    font-weight: 200;
    line-height: 26px;
  }
  .article.draft {
    label {
      color: #f39d00 !important;
    }
  }
  .article.published {
    label {
      color: #33d400 !important;
    }
  }
  .article-preview {
    .article-title {
      font-weight: bold;
      font-size: 24px;
    }
    .article-description {
      video {
        max-width: 400px;
      }
    }
  }
}
