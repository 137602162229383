@import "assets/styles/variable.scss";

.form {
  margin-bottom: 20px;
  label {
    font-weight: 400;
    color: black;
    width: 100%;
    &:not(.inline) {
      display: block;
    }
    margin-bottom: 5px;
  }
  input[type="text"],
  input[type="time"],
  input[type="password"],
  textarea {
    font-size: $textFont;
    font-weight: inherit;
    width: 100%;
    border: 1px solid #d4d4d4;
    background: white;
    color: #777777;
    box-shadow: none;
    height: 48px;
    // line-height: 48px;
    padding: 0 10px;
    &:focus {
      border: 1px solid #537df9;
      box-shadow: 0 0 0 1px #537df9;
    }
    &::placeholder {
      color: #b7b7b7;
    }
  }
  textarea {
    width: 100%;
    min-height: 200px;
    border: 1px solid #d4d4d4;
    font-family: "Roboto";
    font-size: 1em;
    line-height: 1.5em;
    padding: 5px;
    color: $textColor;
  }
  .form-group {
    &.half {
      padding: 0px 5px;
      width: 50%;
      display: inline-block;
      vertical-align: top;
      @media (max-width: 600px) {
        width: 100%;
        display: block;
      }
    }

    &.error {
      input,
      textarea,
      .form-select__control {
        border: 1px solid red !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        input,
        input:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .form-select-container {
    z-index: 1000;
  }
  .form-select__control {
    min-height: 48px;
    height: auto;
    border: 1px solid #dadada;
    box-shadow: none;

    input,
    input:focus {
      border: none !important;
      box-shadow: none !important;
    }
    &:hover {
      border: 1px solid #dadada;
    }
    &.form-select__control--is-focused {
      &:not(.form-select__control--is-disabled) {
        border: 1px solid #537df9;
        box-shadow: 0 0 0 1px #537df9;
      }
    }
  }
  .react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
      display: block;
    }
  }
}
