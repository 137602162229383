.seo-detail-page {
  color: #000;
  section {
    margin-bottom: 80px;
  }
  &.chatgpt {
    .form {
      background: white;
      padding: 15px;
      border: 1px solid #eaeaea;
    }
  }
  .prompt {
    .checked-status {
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      margin-top: -2px;
    }
    .clickable {
      cursor: pointer;
    }
    textarea {
      min-height: 100px;
      max-width: 100%;
      color: #000;
    }
    margin-bottom: 20px;
    .prompt-frame {
      background: white;
      padding: 15px;
      border: 1px solid black;
      &.opened-top {
        border-bottom: 0px;
      }
      &.opened-bottom {
        border-top: 0px;
      }
    }
  }
  .error {
    color: red;
  }
  strong {
    font-weight: 500;
  }
  .actions {
    margin: 20px 0px;
    & > div {
      margin-right: 10px;
      margin-bottom: 4px;
    }
  }
  .page-link {
    margin-bottom: 40px;
  }
  .language-selection {
    display: flex;
    flex-direction: row;
    margin: 20px 0px;
  }
  .warning-box {
    border: 1px solid #f0c371;
    background-color: #ffe7bb;
    padding: 10px;
    margin-bottom: 10px;
    color: black;
    text-align: center;
    .warning-box-message-explanation {
      font-size: 0.8em;
      margin-top: 4px;
    }
  }
}
