.seo-detail-page {
  color: #000;
  .page-link {
    margin-bottom: 40px;
  }
  .language-selection {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }
  .warning-box {
    border: 1px solid #f0c371;
    background-color: #ffe7bb;
    padding: 10px;
    margin-bottom: 10px;
    color: black;
    text-align: center;
    .warning-box-message-explanation {
      font-size: 0.8em;
      margin-top: 4px;
    }
  }
}
