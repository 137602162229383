.dashboard-block-wrapper {
  margin-bottom: 10px;
  padding: 0px 5px;
}
.dashboard-block-wrapper .dashboard-block {
  height: auto;
  padding: 10px;
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 4px;
}
.dashboard-block-wrapper .dashboard-block.align-center {
  text-align: center;
}
.dashboard-block-wrapper .dashboard-block.square {
  height: 335px;
}
.dashboard-block-wrapper .dashboard-block .canvas {
  margin: auto;
}
.dashboard-block-wrapper .dashboard-block .title {
  text-align: left;
  margin: -10px;
  padding: 0px 10px;
  margin-bottom: 5px;
  background-color: #fbfbfb;
  font-size: 0.9em;
  height: 35px;
  line-height: 35px;
}

