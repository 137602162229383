.matchs-detail-page.detail-page {
  .columns {
    margin-bottom: 40px;
    .column-2 {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
    .column-left {
      padding-right: 10px;
    }

    .column-right {
      text-align: right;
      padding-left: 10px;
    }
  }

  .status {
    font-size: 1.8em;
  }

  .event {
    h1 a {
      color: inherit;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }

  .status-action {
    display: flex;
    justify-content: center;
    &.canceled {
      color: red;
      font-size: 20px;
      background: #ff000021;
      padding: 10px;
    }
    &.confirmed {
      color: #37e637;
      font-size: 20px;
      background: #37e63724;
      padding: 10px;
    }
    & > div {
      margin: 0 5px;
    }
  }

  .chat {
    textarea {
      width: 100%;
      height: 200px;
    }
  }

  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      margin: 0px;
      margin-bottom: 10px;
      i {
        margin-right: 10px;
        font-size: 22px;
        width: 25px;
        text-align: center;
      }
      strong {
        margin-right: 10px;
      }
    }
  }
}
