.sidebar {
  width: 200px;
  position: fixed;
  height: 100%;
  background-color: #383838;
  padding: 20px 10px;
  height: 100%;
  z-index: 100;
}
.sidebar .nav-group {
  margin-bottom: 30px;
}
.sidebar .nav-title {
  color: #b4b3bb;
  margin-bottom: 10px;
}
.sidebar .nav-el {
  color: #e2e2e2;
  padding: 15px 5px;
  display: block;
  cursor: pointer;
}
.sidebar .nav-el:hover {
  background-color: #464444;
  color: #00BFA5;
}
.sidebar .nav-el i {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 16px;
}
.sidebar .nav-el i.users {
  background-size: cover;
}
.sidebar .nav-el.active {
  color: #00BFA5;
}
.sidebar a {
  color: inherit;
  text-decoration: initial;
}
@media only screen and (max-width: 1264px) {
  .sidebar {
    width: 60px;
  }
  .sidebar .nav-title {
    display: none;
  }
  .sidebar .nav-el span {
    display: none;
  }
}
@media only screen and (max-device-width: 1264px) {
  .sidebar {
    width: 60px;
  }
  .sidebar .nav-title {
    display: none;
  }
  .sidebar .nav-el span {
    display: none;
  }
}

.mobile-navbar {
  /*ul li {
    opacity: 1 !important;
    visibility: visible !important;
  }*/
  display: none;
}
.mobile-navbar button {
  background-color: #00BFA5 !important;
}
.mobile-navbar ul li a span > div, .mobile-navbar ul li a span > img {
  float: left !important;
}
@media only screen and (max-device-width: 1400px) {
  .mobile-navbar {
    display: block;
  }
}
@media only screen and (max-width: 1264px) {
  .mobile-navbar {
    display: block;
  }
}

