.form {
  margin-bottom: 20px;
}
.form label {
  font-weight: 400;
  color: black;
  width: 100%;
  margin-bottom: 5px;
}
.form label:not(.inline) {
  display: block;
}
.form input[type=text],
.form input[type=time],
.form input[type=password],
.form textarea {
  font-size: 16px;
  font-weight: inherit;
  width: 100%;
  border: 1px solid #d4d4d4;
  background: white;
  color: #777777;
  box-shadow: none;
  height: 48px;
  padding: 0 10px;
}
.form input[type=text]:focus,
.form input[type=time]:focus,
.form input[type=password]:focus,
.form textarea:focus {
  border: 1px solid #537df9;
  box-shadow: 0 0 0 1px #537df9;
}
.form input[type=text]::placeholder,
.form input[type=time]::placeholder,
.form input[type=password]::placeholder,
.form textarea::placeholder {
  color: #b7b7b7;
}
.form textarea {
  width: 100%;
  min-height: 200px;
  border: 1px solid #d4d4d4;
  font-family: "Roboto";
  font-size: 1em;
  line-height: 1.5em;
  padding: 5px;
  color: #777777;
}
.form .form-group.half {
  padding: 0px 5px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 600px) {
  .form .form-group.half {
    width: 100%;
    display: block;
  }
}
.form .form-group.error input,
.form .form-group.error textarea,
.form .form-group.error .form-select__control {
  border: 1px solid red !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.form .form-group.error input input,
.form .form-group.error input input:focus,
.form .form-group.error textarea input,
.form .form-group.error textarea input:focus,
.form .form-group.error .form-select__control input,
.form .form-group.error .form-select__control input:focus {
  border: none !important;
  box-shadow: none !important;
}
.form .form-select-container {
  z-index: 1000;
}
.form .form-select__control {
  min-height: 48px;
  height: auto;
  border: 1px solid #dadada;
  box-shadow: none;
}
.form .form-select__control input,
.form .form-select__control input:focus {
  border: none !important;
  box-shadow: none !important;
}
.form .form-select__control:hover {
  border: 1px solid #dadada;
}
.form .form-select__control.form-select__control--is-focused:not(.form-select__control--is-disabled) {
  border: 1px solid #537df9;
  box-shadow: 0 0 0 1px #537df9;
}
.form .react-datepicker-wrapper {
  display: block;
}
.form .react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
}

