$sidebarWidth: 200px;
$sidebarCompactWidth: 60px;
$primaryColor: #00BFA5;

$textColor: #777777;
$textFont: 16px;

$breakpoint-xsmall: 600px;
$breakpoint-small: 944px;
$breakpoint-medium: 1264px;
$breakpoint-large: 1920px;