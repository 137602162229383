.pagination {
  list-style: none;
  text-align: center;
}
.pagination li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: #00BFA5;
  color: white;
  cursor: pointer;
}
.pagination li.break, .pagination li a {
  padding: 9px 12px;
}
.pagination li a {
  display: block;
}
.pagination li.selected {
  background-color: white;
  color: #777777;
  border: 1px solid #777777;
}

