.feedbacks-detail-page .author {
  margin-bottom: 20px;
}
.feedbacks-detail-page .author .picture {
  margin: auto;
  display: block;
}
.feedbacks-detail-page .link {
  margin-bottom: 20px;
}

