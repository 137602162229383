@import 'assets/styles/variable.scss';

.status-list {
  ul li {
    color: #c1c1c1;
  }
  ul li.pending {
    color: #5c80ec;
  }
  ul li.active {
    color: $primaryColor;
  }

}