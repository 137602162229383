.users-detail-page .error {
  font-size: 20px;
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-weight: 200;
  line-height: 26px;
}

