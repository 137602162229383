@import 'assets/styles/variable.scss';

.users-page {
  .create-user {
    margin-right: 20px;
    button {
      padding: 0px 10px !important;
    }
  }
  .export {
    margin-right: 20px;
  }
  .criterias {
    margin-top: 10px;
    span {
      cursor: pointer;
      font-size: 12px;
      padding: 10px;
      background-color: #d6d6d6;
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: middle;
      &.active {
        background-color: $primaryColor;
        color: white;
      }
    }
  }
}