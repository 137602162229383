.matchs-detail-page.detail-page .columns {
  margin-bottom: 40px;
}
.matchs-detail-page.detail-page .columns .column-2 {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.matchs-detail-page.detail-page .columns .column-left {
  padding-right: 10px;
}
.matchs-detail-page.detail-page .columns .column-right {
  text-align: right;
  padding-left: 10px;
}
.matchs-detail-page.detail-page .status {
  font-size: 1.8em;
}
.matchs-detail-page.detail-page .event h1 a {
  color: inherit;
}
.matchs-detail-page.detail-page .event img {
  width: 60px;
  height: 60px;
}
.matchs-detail-page.detail-page .status-action {
  display: flex;
  justify-content: center;
}
.matchs-detail-page.detail-page .status-action.canceled {
  color: red;
  font-size: 20px;
  background: rgba(255, 0, 0, 0.1294117647);
  padding: 10px;
}
.matchs-detail-page.detail-page .status-action.confirmed {
  color: #37e637;
  font-size: 20px;
  background: rgba(55, 230, 55, 0.1411764706);
  padding: 10px;
}
.matchs-detail-page.detail-page .status-action > div {
  margin: 0 5px;
}
.matchs-detail-page.detail-page .chat textarea {
  width: 100%;
  height: 200px;
}
.matchs-detail-page.detail-page ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.matchs-detail-page.detail-page ul li {
  margin: 0px;
  margin-bottom: 10px;
}
.matchs-detail-page.detail-page ul li i {
  margin-right: 10px;
  font-size: 22px;
  width: 25px;
  text-align: center;
}
.matchs-detail-page.detail-page ul li strong {
  margin-right: 10px;
}

