@import 'assets/styles/variable.scss';

table {
  tr {
    /*cursor: pointer;*/
    &.archived {
      background-color: #ffdfdf;
    }
    &.done {
      background-color: #e7ffdf;
    }
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    a svg {
      color: rgb(160, 160, 160) !important;
    }
    a {
      color: $primaryColor;
      width: 100%;
      height: 100%;
      display: flex;
      /*justify-content: center;*/
      align-items: center;
    }
  }
  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: block;
  }

  &.unselectable {
    thead, tbody {
      tr > td:first-child, tr > th:first-child {
        display: none;
      }
    }
  }
}