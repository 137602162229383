.form-input {
  line-height: 45px;
  padding: 0px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  font-size: 16px;
  color: #777777;
  font-weight: 300;
  min-width: 250px;
}
.form-input.full-width {
  width: 100%;
}
.form-input::placeholder {
  color: #c3c3c3;
}

