@import 'assets/styles/variable.scss';

.pagination {
  list-style: none;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 4px;
    border-radius: 4px;
    background-color: $primaryColor;
    color: white;
    cursor: pointer;
    &.break, a {
      padding: 9px 12px;
    }
    a {
      display: block;
    }
    &.selected {
      background-color: white;
      color: $textColor;
      border: 1px solid $textColor;
    }
  }
}