@import 'assets/styles/variable.scss';

.layout {
  padding-bottom: 50px !important;
  /*height: 100%;*/
  .snackbar {
    left: 0px !important;
    right: 0px !important;
    transform: initial !important;
    z-index: 10000 !important;
    & > div {
      font-size: 1.2em !important;
      height: auto !important;
      line-height: 1.2em !important;
      text-align: center !important;
      padding: 10px !important;
      width: 100% !important;
      max-width: 100% !important;
      background-color: $primaryColor !important;
    }
  }
  .snackbar.error > div {
    background-color: red !important;
  }
  .container {
    padding: 20px;
    padding-left: $sidebarWidth + 20px;
    height: 100%;
    .title-page {
      background-color: white;
      margin: -20px;
      margin-bottom: 40px;
      font-size: 1.3em;
      line-height: 60px;
      text-align: left;
      padding: 0px 20px;
    }

    @media only screen and (max-device-width:$breakpoint-medium) {
      & {
        padding-left: $sidebarCompactWidth + 20px;
      }
    }

    @media only screen and (max-width:$breakpoint-medium) {
      & {
        padding-left: $sidebarCompactWidth + 20px;
      }
    }

    @media only screen and (max-width:$breakpoint-xsmall) {
      & {
        padding-left: $sidebarCompactWidth;
        padding-right: 0px;
      }
    }
  }
}