.feedbacks-detail-page {
  .author {
    margin-bottom: 20px;
    .picture {
      margin: auto;
      display: block;
    }
  }
  .link {
    margin-bottom: 20px;
  }
  form {
  }
}