.login-page {
  margin: auto;
  max-width: 600px;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  form {
    width: 100%;
    padding: 100px 50px;
    background-color: white;
    margin-bottom: 50px;
  }
}