.layout {
  padding-bottom: 50px !important;
  /*height: 100%;*/
}
.layout .snackbar {
  left: 0px !important;
  right: 0px !important;
  transform: initial !important;
  z-index: 10000 !important;
}
.layout .snackbar > div {
  font-size: 1.2em !important;
  height: auto !important;
  line-height: 1.2em !important;
  text-align: center !important;
  padding: 10px !important;
  width: 100% !important;
  max-width: 100% !important;
  background-color: #00BFA5 !important;
}
.layout .snackbar.error > div {
  background-color: red !important;
}
.layout .container {
  padding: 20px;
  padding-left: 220px;
  height: 100%;
}
.layout .container .title-page {
  background-color: white;
  margin: -20px;
  margin-bottom: 40px;
  font-size: 1.3em;
  line-height: 60px;
  text-align: left;
  padding: 0px 20px;
}
@media only screen and (max-device-width: 1264px) {
  .layout .container {
    padding-left: 80px;
  }
}
@media only screen and (max-width: 1264px) {
  .layout .container {
    padding-left: 80px;
  }
}
@media only screen and (max-width: 600px) {
  .layout .container {
    padding-left: 60px;
    padding-right: 0px;
  }
}

